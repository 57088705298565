export function tagManagerScript() {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', process.env.NEXT_PUBLIC_GTM_ID);

  if (window.gtag) {
    window.gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  }
}

export function getGoogleAnalyticsClientID() {
  var cookie = {};
  document.cookie.split(';').forEach(function (el) {
    var splitCookie = el.split('=');
    var key = splitCookie[0].trim();
    var value = splitCookie[1];
    cookie[key] = value;
  });
  return cookie['_ga']?.substring(6);
}

export function consentModeDefaultsScript() {
  return `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('consent', 'default', {
      'analytics_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'ad_storage': 'denied',
      'wait_for_update': 500,
    });

    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_ID}', {
      page_path: window.location.pathname,
    });
  `;
}
